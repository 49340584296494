import { useState, useRef } from 'react';
import React from 'react';
import {  Timeline, Text, TextInput, Title, Center, Button, Loader } from '@mantine/core';
import {HederaLedger} from "./deploy_smart_contract";
import { FaSearch } from 'react-icons/fa';
import jsPDF from "jspdf";
import styled from 'styled-components';
import { Prism } from '@mantine/prism';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly } from '@fortawesome/free-solid-svg-icons';
import logoImage from '../../src/assets/Logo.png';
import { useDispatch, useSelector } from "react-redux";
import { getFetchLedgerLot, getFetchLedgerLotHistory } from "../Redux/products/slice";
import { useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { BASE_URL } from '../Redux/apiConfig';

const Row = ({ children, ...props }) => (
  <div className="row" style={{alignItems: "center", justifyContent: "center", marginTop: "40px" }}>{children}</div>
);

const Column = ({ children, ...props }) => (
  <div className="col" style={{alignItems: "center", justifyContent: "center" }}>{children}</div>
);

const ProductSearch = ({  value, label, name, placeholder, type, onChange }) => {
  const intl = useIntl();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextInput
        placeholder={intl.formatMessage({ id:"explorer.search.placeholder" })}
        value={value}
        onChange={onChange}
        // style={{ marginRight: "16px" }}
      />
    </div>
  );
}

function ProductState({ product }) {
  const codeBlockStyle = {
    maxHeight: "none",
    // border: "1px solid #ddd",
    padding: "10px",
    overflow: "auto",
  };
  return (
      <Prism  style={codeBlockStyle} colorScheme="dark" language="json" >
        {JSON.stringify(product, null, 2)}
      </Prism>
  );
}

function ProductTimeline({ productHist }) {
  const sortedItems = Object.entries(productHist)
    .sort(([linkA, itemA], [linkB, itemB]) => {
      // Get the dates for comparison
      let dateA;
      let dateB;

      // switch (itemA.method) {
      //   case 'transform':
      //     dateA = itemA[1];
      //     dateB = itemB[1];
      //     break;
      //   case 'transport':
      //     dateA = itemA[0];
      //     dateB = itemB[0];
      //     break;
      //   case 'sell':
      //     dateA = itemA[1];
      //     dateB = itemB[1];
      //     break;
      //   default:
      //     // Handle the default case if needed
      //     dateA = itemA["link"];
      //     dateB = itemB["link"];
      // }
      // temporary
      dateA = itemA["link"];
      dateB = itemB["link"];

      // Compare the dates
      const dateComparison = dateA?.localeCompare(dateB);

      if (dateComparison === 0) {
        // If dates are equal, compare timestamps
        return itemA["link"].localeCompare(itemB["link"]);
      }

      // Return the result of the date comparison
      return dateComparison;
    })
    .map(([timestamp, item]) => {
      // Customize the output based on the method name
      let description, title;
      switch (item.method) {
        case 'transform':
          // var title = item.method.charAt(0).toUpperCase() + item.method.slice(1);
          title = <FormattedMessage id="explorer.timeline.transformation" />;
          // Modify the title or description for 'transform' method
          description = (
            <>
              <FormattedMessage id="explorer.timeline.process" /> {item[0]}
              <br />
              <FormattedMessage id="product.period1" /> {item[1]}
              <br />
              <FormattedMessage id="product.location1" /> {item[2]}
            </>
          );
          break;
        case 'transport':
          title = <FormattedMessage id="explorer.timeline.transport" />;
          // Modify the title or description for 'transport' method
          description = (
            <>
              <FormattedMessage id="product.period1" /> {item[0]}
              <br />
              <FormattedMessage id="product.location1" /> {item[1]}
            </>
          );
          break;
        case 'sell':
          title = <FormattedMessage id="explorer.timeline.sell" />;
          // Modify the title or description for 'sell' method
          description = <FormattedMessage id="explorer.timeline.prodforsale" />;
          break;
        default:
          // Handle the default case if needed
          title = <FormattedMessage id="explorer.timeline.operation" />;
          description = (           
          <>
          <FormattedMessage id="explorer.timeline.newOwneradr" /> {item[0]}
          </>);
      }

      return {
        timestamp,
        title,
        description
      };
    });

    return (
      <Timeline active={0} bulletSize={20} lineWidth={2}>
        {sortedItems.map((item, index) => (
          <Timeline.Item
            key={index}
            bullet={<FontAwesomeIcon icon={faDolly} />}
            title={item.title}
          >
            <Text color="dimmed" size="sm">
              {item.description}
            </Text>
          </Timeline.Item>
        ))}
      </Timeline>
    );
  }  

const styles = {
  container: {
    width: '100%',
    maxWidth: '1200px',
    margin: 'auto',
    padding: '40px', // Adding padding to the container
    boxSizing: 'border-box', // Ensuring padding doesn't increase the total width
    overflowY: "auto", 
  },
  text: {
    fontFamily: 'Montserrat, sans-serif',
    marginTop: '40px',
    marginBottom: '40px',
    textAlign: 'center', // Center-align the text
    overflowY: "auto", 
  },
  flexContainer: {
    display: 'flex',
    direction: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflowY: "auto", 
  },
  column: {
    maxWidth: '600px',
    flex: '100%',
    overflowY: "auto", 
  },
  row: {
    width: '100%',
    overflowY: "auto", 
  },
};

export const ProductCertificate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [productDict, setProductDict] = useState({});
  const [productHist, setProductHist] = useState({});
  const dispatch = useDispatch();
  // Get the result of getFetchLedgerLot from Redux store state
  const fetchLedgerLot = useSelector((state) => state.products.fetchLedgerLot);
  const fetchLedgerLotHistory = useSelector((state) => state.products.fetchLedgerLotHistory);
  const intl = useIntl();
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState("");
  const selectedLanguage = useSelector((state) => state.language);

  useEffect(() => {
    if (fetchLedgerLot?.result?.ProductName) {
      const productName = fetchLedgerLot.result.ProductName;
  
      // Split the ProductName at the first comma
      const [mainProductName, ...rest] = productName.split(",");
      const additionalFields = {};
  
      // Process the rest of the text for key-value pairs
      rest.join(",").split("\\r\\n").forEach((line) => {
        const [key, value] = line.split(":").map((str) => str.trim());
        if (key && value) {
          additionalFields[key] = value;
        }
      });
  
      // Update the fetchLedgerLot.result dictionary
      setProductDict({
        ...fetchLedgerLot,
        result: {
          ...fetchLedgerLot.result,
          ProductName: mainProductName.trim(), // Set ProductName to the text before the first comma
          ...additionalFields,
        },
      });
    }
  }, [fetchLedgerLot]);

  useEffect(() => {
    // This useEffect hook will run whenever `fetchLedgerLotResult` changes
    setProductHist(fetchLedgerLotHistory);
  }, [fetchLedgerLotHistory]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (productDict && productDict.result && productDict.result.ProductID) {
        try {
          const productResponse = await fetch(`${BASE_URL}/getProductCertificate?product_id=${productDict.result.ProductID}&targetLanguage=${selectedLanguage}`);
  
          if (productResponse.ok) {
            const qrCodeUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/qrcode/${productDict.result.ProductID}.png`;
            setQrCodeImageUrl(qrCodeUrl);
          }
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, productDict, selectedLanguage]);
  

  const handleClick = async () => {
    setIsLoading(true);
    setInputValue(inputValue);
    try {
      // Dispatch the action to fetch data from the server
      await dispatch(getFetchLedgerLotHistory({ inputValue }));

      // The fetchLedgerLotResult is now available from the useSelector hook
      setProductHist(fetchLedgerLotHistory);  

      // // Dispatch the action to fetch data from the server
      await dispatch(getFetchLedgerLot({ inputValue }));

      // // The fetchLedgerLotResult is now available from the useSelector hook
      setProductDict(fetchLedgerLot);

      // Clear productDict and productHist
      setProductDict({});
      setProductHist({});

      // Clear the QR code image URL
      setQrCodeImageUrl("");

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }

  };

  const generatePDF = (data, productDict) => {
    const pdf = new jsPDF();
  
    // Set font styles
    pdf.setFont('helvetica');
    pdf.setFontSize(10);
  
    // Logo
    pdf.addImage(logoImage, 'PNG', 20, 20, 15, 10);
  
    // Title
    pdf.setFontSize(18);
    pdf.setFont('helvetica', 'bold');
    var titleText = intl.formatMessage({ id:"certificate.title"}) + inputValue;
    var titleWidth = pdf.getTextWidth(titleText);
    var titleX = (pdf.internal.pageSize.getWidth() - titleWidth) / 2;
  
    pdf.text(titleText, titleX, 40);
  
    // Certificate content
    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'normal');
    pdf.textWithLink(intl.formatMessage({ id:"certificate.salutation" }), 20, 60, { url: '' });
  
    var textContent = intl.formatMessage({ id:"certificate.para.line1" }) +
    intl.formatMessage({ id:"certificate.para.line2" }) +
    intl.formatMessage({ id:"certificate.para.line3" });
    var textLines = pdf.splitTextToSize(textContent, 170);
  
    var lineHeight = 6;
    var startY = 70;
    var availableSpace = 260 - startY; // Adjusted available space for the content
    if (textLines.length * lineHeight > availableSpace) {
      startY += availableSpace - (textLines.length * lineHeight);
    }
  
    pdf.text(textLines, 20, startY);
  
    // Product description
    pdf.setFont('helvetica', 'bold'); // Set font style to bold
    pdf.textWithLink(intl.formatMessage({ id:"certificate.prod.descript" }), 20, startY + 25, { url: '' });
  
    // Format and display productDict data
    pdf.setFont('helvetica', 'normal'); // Reset font style to normal
    var tabWidth = 40; // Width of a tab
    var tabY = startY + 37; // Start Y position for the tabular data
    Object.entries(productDict).forEach(([key, value]) => {
      // Make the key bold
      pdf.setFont('helvetica', 'bold');
      pdf.text(key + ':', 30, tabY);
  
      // Reset the font style to normal for the value
      pdf.setFont('helvetica', 'normal');
      const valueLines = pdf.splitTextToSize(value, 170 - tabWidth);
      const formattedValue = valueLines.map((line) => '\t' + line); // Add a tab before each line
      pdf.text(formattedValue, 30 + tabWidth, tabY);
      tabY += (valueLines.length - 1) * lineHeight + 6; // Adjust tabY based on the number of lines in the value
    });
  
    // Update currentY for history of events
    var currentY = tabY + 12;
  
    // History of events
    pdf.setFont('helvetica', 'bold');
    pdf.textWithLink(intl.formatMessage({ id:"certificate.history" }), 20, currentY, { url: '' });
  
    var events = Object.entries(productHist).map(([timestamp, item]) => {
      var description;
      var eventDate;
      switch (item.method) {
        case 'transform':
          description = intl.formatMessage({ id:"explorer.timeline.transformation" }) + item[0] + '\n' + intl.formatMessage({ id:"product.period1" }) + item[1] + '\n' + intl.formatMessage({ id:"product.location1" }) + item[2];
          eventDate = item[8];
          break;
        case 'transport':
          description = intl.formatMessage({ id:"product.period1" }) + item[0] + '\n' + intl.formatMessage({ id:"product.location1" }) + item[1];
          eventDate = item[7];
          break;
        case 'sell':
          description = intl.formatMessage({ id:"explorer.timeline.prodforsale" });
          eventDate = timestamp;
          break;
        default:
          description = item[1];
          eventDate = timestamp;
      }
    
      return {
        title: item.method,
        description,
        link: item.link,
        date: eventDate,
        timestamp, // Add the timestamp property for sorting if needed
      };
    });
    
    events.sort((a, b) => {
      let dateA;
      let dateB;
    
      // switch (a.title) {
      //   case 'transform':
      //     dateA = a.date;
      //     dateB = b.date;
      //     break;
      //   case 'transport':
      //     dateA = a.date;
      //     dateB = b.date;
      //     break;
      //   default:
      //     // Handle other cases if needed
      //     dateA = a.timestamp;
      //     dateB = b.timestamp;
      //     break;
      // }

      dateA = a["link"];
      dateB = b["link"];
    
      const dateComparison = dateA?.localeCompare(dateB);
    
      if (dateComparison === 0) {
        // If dates are equal, compare timestamps
        return a.timestamp.localeCompare(b.timestamp);
      }
    
      return dateComparison;
    });    
  
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 20;
    let lastProcessedIndex = 0;
  
    events.sort((a, b) => a.link.localeCompare(b.link));
    currentY += 12;
  
    while (lastProcessedIndex < events.length) {
      // Iterate through events, starting from the last processed index
      for (let index = lastProcessedIndex; index < events.length; index++) {
        const event = events[index];
        pdf.setFillColor(0, 0, 0); // Reset the fill color to black
        pdf.setTextColor(0); // Reset the text color to black
        pdf.setFont('helvetica', 'normal');
        const bulletPoint = '\u2022'; // Unicode for bullet point
        const formattedDescription = `\t${event.description ? event.description.replace(/\n/g, '\n\t') : ''}`;
        pdf.setFontSize(10); // Set the font size for the bullet point and title
        pdf.textWithLink(`${bulletPoint} ${event.title.charAt(0).toUpperCase() + event.title.slice(1)}:`, 20, currentY, { url: '' });
        pdf.setFontSize(10); // Set the font size for the description
        const descriptionLines = pdf.splitTextToSize(formattedDescription, 170);
        pdf.text(descriptionLines, 20, currentY + 5);
        const descriptionHeight = descriptionLines.length * lineHeight;
        // Add the new line with the link
        // Set the font color, size, and underline styles
        pdf.setFillColor(0, 0, 255); // Set the fill color to blue
        pdf.setTextColor(0, 0, 255); // Set the text color to blue
        pdf.setFontSize(10); // Set the font size for the link
        // pdf.setFont('', 'U'); // Underline the text
        pdf.textWithLink(`\t` + intl.formatMessage({ id:"Link to Hedera ledger" }), 20, currentY + 2 + descriptionHeight, { url: event.link, target: '_blank', rel: 'noopener noreferrer' });
        pdf.setFillColor(0, 0, 0); // Reset the fill color to black
        pdf.setTextColor(0); // Reset the text color to black
        pdf.setFont('helvetica', 'normal');
        currentY += descriptionHeight + 10; // Update currentY based on the height of the description and add spacing between events        
  
        const contentHeight = currentY + margin;
        if (contentHeight > pageHeight - margin) {
          // If content spills over to the next page, break the loop and update the last processed index
          lastProcessedIndex = index + 1;
          break;
        } else if (index === events.length - 1) {
          // If all events have been processed, update the last processed index to exit the loop
          lastProcessedIndex = events.length;
        }
        // Adjust currentY to ensure vertical alignment
        currentY += lineHeight - 5;
      }
  
      if (lastProcessedIndex < events.length) {
        // Add a new page if there are remaining events to process
        pdf.addPage();
        currentY = margin;
      }
    }

    // Add horizontal line at the end of the document
    const pageWidth = pdf.internal.pageSize.getWidth();
    const lineY = pdf.internal.pageSize.getHeight() - 10; // Adjust the Y position as needed
    pdf.setLineWidth(0.5); // Set the line width
    pdf.setDrawColor(0, 0, 0); // Set the line color to black
    pdf.line(20, lineY, pageWidth - 20, lineY); // Draw the line
  
    return pdf;
  };
  
  
  const handleDownloadPDF = () => {
    const pdfData = {};

    Object.entries(productHist).forEach(([timestamp, item]) => {
      let transformation;
      let location;
      let period;
    
      if (item.method === 'transformation') {
        transformation = 'Transformation'; // Transformation description
        location = item[2];
        period = item[1];
      } else if (item.method === 'transport') {
        transformation = 'Transport'; // Transformation description
        location = item[1];
        period = item[0];
      } else if (item.method === 'sell') {
        transformation = 'Product for Sell'; // Transformation description
      } 
    
      pdfData[timestamp] = {
        Transformation: transformation || '',
        Location: location || '',
        Period: period || '',
        // Add more data fields as needed
      };
    });
    
  
    const pdf = generatePDF(pdfData, productDict.result);
  
    pdf.save(intl.formatMessage({ id:"VertoProductCertificateHistory_#" })+inputValue+`.pdf`);
  };
  
  const TitleDescriptionWrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
  `;

  return (
    <div style={styles.container}>
    <TitleDescriptionWrapper style={{marginTop: "40px", marginBottom: "40px" }}>
      <Title order={3} weight={200} align="center" style={{ fontFamily: 'Montserrat, sans-serif', marginTop: "40px", marginBottom: "40px" }}>
        <FormattedMessage id="explorer.title" />
      </Title>
      <Text  style={styles.text}>
        <FormattedMessage id="explorer.text" />
      </Text>
    </TitleDescriptionWrapper>
    <Center style={{marginTop: "40px", marginBottom: "40px" }}>
      <div style={styles.flexContainer}>
        <div style={{ ...styles.column }}>
          {/* Row 1 - Search and Download */}
            <div style={styles.row}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', maxWidth: '400px'}}>
                  <ProductSearch
                    type="text"
                    value={inputValue}
                    placeholder={<FormattedMessage id="label.product.name" />}
                    label="Name"
                    name="name"
                    onChange={handleChange}
                  />
                  <Button variant="outline" onClick={handleClick}>
                    {isLoading ? <Loader size="xs" color="gray" /> : <FaSearch />}
                  </Button>
                  <Button color="teal" onClick={handleDownloadPDF}>
                    <FormattedMessage id="button.dload.pdf" />
                  </Button>
                </div>
            </div>
                {/* <CertificateContainer id="certificate" ref={certificateRef}></CertificateContainer> */}
        </div>
          {/* Row 2 - Product Information and History */}
          <div style={{ ...styles.column }}>
          <Column >
              <Row >
                {/* QR Code Image */}
                {qrCodeImageUrl && (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={qrCodeImageUrl} alt="QR Code" style={{ maxWidth: '100%' }} />
                  </div>
                )}
              </Row>
              <Row >
                <Title order={3} weight={200} align="center">
                  <FormattedMessage id="explorer.section.pinfo" />
                </Title>
              </Row>
              <Row >
                <ProductState product={productDict} style={{ width: "100%" }}/>
              </Row>
              <Row  >
                <Column>
                  <Title order={3} weight={200} align="center">
                    <FormattedMessage id="explorer.section.phistory" />
                  </Title>
                </Column>
              </Row>
              <Row >
                <Column>
                  {productHist ? (
                    <ProductTimeline productHist={productHist} />
                  ) : (
                    <p>...</p>
                  )}
                </Column>
              </Row>
        </Column>
        </div>
      </div>
    </Center>
    </div>
  );
};

export default ProductCertificate;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getFetchLedgerLot, getFetchLedgerLotHistory } from "../Redux/products/slice";
import { BASE_URL } from '../Redux/apiConfig';
import { Timeline, Text, Title, Center, Button, Loader, Badge, Group, MantineProvider, Card } from '@mantine/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { Prism } from '@mantine/prism';
import jsPDF from "jspdf";
import styled from 'styled-components';
import logoImage from '../../src/assets/Logo.png';
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly } from '@fortawesome/free-solid-svg-icons';

const Row = ({ children }) => (
  <div className="row" style={{ alignItems: "center", justifyContent: "center", marginTop: "40px" }}>{children}</div>
);

const Column = ({ children }) => (
  <div className="col" style={{ alignItems: "center", justifyContent: "center" }}>{children}</div>
);

const ProductState = ({ product }) => {
  // Extract relevant details for Owner section
  const ownerDetails = {
    "Owner Address": product?.result?.OwnerPublicAddress,
    "Product Name": product?.result?.ProductName,
    "UPC": product?.result?.UPC,
  };

  // Extract all other details for Specifications section
  const productSpecifications = Object.entries(product?.result || {})
    .filter(([key]) => !["OwnerPublicAddress", "ProductName", "UPC"].includes(key))
    .map(([key, value]) => ({ label: key, value }));

  return (
    <div style={{ marginTop: "20px", maxWidth: "600px", margin: "auto" }}>
      {/* Owner Details Section */}
      <Card withBorder radius="md" style={{ marginBottom: "20px" }}>
        <Card.Section>
          <Title order={4} style={{ padding: "10px 20px" }}>
            Owner Details
          </Title>
        </Card.Section>
        <Group direction="column" spacing="sm" style={{ padding: "20px" }}>
          {Object.entries(ownerDetails).map(([key, value]) => (
            <div key={key}>
              <Text size="sm" weight={500}>
                {key}:
              </Text>
              <Text size="sm" color="dimmed">
                {value || "N/A"}
              </Text>
            </div>
          ))}
        </Group>
      </Card>

      {/* Product Specifications Section */}
      <Card withBorder radius="md">
        <Card.Section>
          <Title order={4} style={{ padding: "10px 20px" }}>
            Product Specifications
          </Title>
        </Card.Section>
        <Group direction="column" spacing="sm" style={{ padding: "20px" }}>
          {productSpecifications.map(({ label, value }) => (
            <div key={label}>
              <Text size="sm" weight={500}>
                {label}:
              </Text>
              <Text size="sm" color="dimmed">
                {value || "N/A"}
              </Text>
            </div>
          ))}
        </Group>
      </Card>
    </div>
  );
};

function ProductTimeline({ productHist }) {
    const sortedItems = Object.entries(productHist)
      .sort(([linkA, itemA], [linkB, itemB]) => {
        // Get the dates for comparison
        let dateA;
        let dateB;
  
        // switch (itemA.method) {
        //   case 'transform':
        //     dateA = itemA[1];
        //     dateB = itemB[1];
        //     break;
        //   case 'transport':
        //     dateA = itemA[0];
        //     dateB = itemB[0];
        //     break;
        //   case 'sell':
        //     dateA = itemA[1];
        //     dateB = itemB[1];
        //     break;
        //   default:
        //     // Handle the default case if needed
        //     dateA = itemA["link"];
        //     dateB = itemB["link"];
        // }
        // temporary
        dateA = itemA["link"];
        dateB = itemB["link"];
  
        // Compare the dates
        const dateComparison = dateA?.localeCompare(dateB);
  
        if (dateComparison === 0) {
          // If dates are equal, compare timestamps
          return itemA["link"].localeCompare(itemB["link"]);
        }
  
        // Return the result of the date comparison
        return dateComparison;
      })
      .map(([timestamp, item]) => {
        // Customize the output based on the method name
        let description, title;
        switch (item.method) {
          case 'transform':
            // var title = item.method.charAt(0).toUpperCase() + item.method.slice(1);
            title = <FormattedMessage id="explorer.timeline.transformation" />;
            // Modify the title or description for 'transform' method
            description = (
              <>
                <FormattedMessage id="explorer.timeline.process" /> {item[0]}
                <br />
                <FormattedMessage id="product.period1" /> {item[1]}
                <br />
                <FormattedMessage id="product.location1" /> {item[2]}
              </>
            );
            break;
          case 'transport':
            title = <FormattedMessage id="explorer.timeline.transport" />;
            // Modify the title or description for 'transport' method
            description = (
              <>
                <FormattedMessage id="product.period1" /> {item[0]}
                <br />
                <FormattedMessage id="product.location1" /> {item[1]}
              </>
            );
            break;
          case 'sell':
            title = <FormattedMessage id="explorer.timeline.sell" />;
            // Modify the title or description for 'sell' method
            description = <FormattedMessage id="explorer.timeline.prodforsale" />;
            break;
          default:
            // Handle the default case if needed
            title = <FormattedMessage id="explorer.timeline.operation" />;
            description = (           
            <>
            <FormattedMessage id="explorer.timeline.newOwneradr" /> {item[0]}
            </>);
        }
  
        return {
          timestamp,
          title,
          description
        };
      });
  
      return (
        <div style={{ marginTop: "20px", maxWidth: "600px", margin: "auto" }}>
        <Timeline active={0} bulletSize={20} lineWidth={2}
            styles={(theme) => ({
              item: {
                backgroundColor: theme.colorScheme === 'dark' ? '#1A1B1E' : '#fff',
              },
              itemTitle: {
                color: theme.colorScheme === 'dark' ? theme.colors.gray[2] : theme.black,
              },
              itemBullet: {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[2],
              },
              itemLine: {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[3],
              },
            })}
          >
          {sortedItems.map((item, index) => (
            <Timeline.Item
              key={index}
              bullet={<FontAwesomeIcon icon={faDolly} />}
              title={item.title}
            >
              <Text color="dimmed" size="sm">
                {item.description}
              </Text>
            </Timeline.Item>
          ))}
        </Timeline>
        </div>
      );
    }  

const CertificatePage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [productDict, setProductDict] = useState({});
  const [productHist, setProductHist] = useState({});
  const dispatch = useDispatch();
  const fetchLedgerLot = useSelector((state) => state.products.fetchLedgerLot);
  const fetchLedgerLotHistory = useSelector((state) => state.products.fetchLedgerLotHistory);
  const intl = useIntl();
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState("");
  const [isSpecialAccount, setIsSpecialAccount] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(getFetchLedgerLot({ inputValue: id }));
        await dispatch(getFetchLedgerLotHistory({ inputValue: id }));

        // Once the data is fetched, update the productDict and check the special account condition
        const product = fetchLedgerLot?.result;
        if (product?.OwnerPublicAddress === '0.0.3123766') { // Replace with your special account condition
          setIsSpecialAccount(true);
        } else {
          setIsSpecialAccount(false);
        }        
  
        // Process `fetchLedgerLot` to extract additional fields
        if (fetchLedgerLot?.result?.ProductName) {
          const productName = fetchLedgerLot.result.ProductName;
  
          // Extract main ProductName and parse additional fields
          const [mainProductName, ...rest] = productName.split(",");
          const additionalFields = {};
  
          // Join the rest of the text and split by `\r\n` to process key-value pairs
          rest.join(",").split("\\r\\n").forEach((line) => {
            const [key, value] = line.split(":").map((str) => str.trim());
            if (key && value) {
              additionalFields[key] = value;
            }
          });
  
          // Update the fetchLedgerLot.result dictionary
          setProductDict({
            ...fetchLedgerLot,
            result: {
              ...fetchLedgerLot.result,
              ProductName: mainProductName.trim(),
              ...additionalFields,
            },
          });
        }
  
        // Set product history
        setProductHist(fetchLedgerLotHistory);
  
        // Set QR code image URL
        if (fetchLedgerLot.result && fetchLedgerLot.result.ProductID) {
          const qrCodeUrl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/qrcode/${fetchLedgerLot.result.ProductID}.png`;
          setQrCodeImageUrl(qrCodeUrl);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, id, fetchLedgerLot]);   

  // Separate effect for fetching the certificate
  useEffect(() => {
    const fetchProductCertificate = async () => {
      if (fetchLedgerLot?.result?.ProductID) {
        try {
          await fetch(`${BASE_URL}/getProductCertificate?product_id=${fetchLedgerLot.result.ProductID}&targetLanguage=en`);
        } catch (error) {
          console.error("Failed to fetch product certificate:", error);
        }
      }
    };

    fetchProductCertificate();
  }, [fetchLedgerLot?.result?.ProductID]);

  return (
    <MantineProvider theme={{ colorScheme: isSpecialAccount ? 'dark' : 'light' }}>
      <div
        style={{
          padding: '30px',
          background: isSpecialAccount
            ? 'linear-gradient(to bottom, #121212, #1A1B1E)' // Example gradient for dark mode
            : '#f9f9f9', // Light background color
          minHeight: '100vh', // Ensure the background covers the entire viewport
        }}
      >
      <Title
        order={3}
        weight={200}
        align="center"
        style={{
          color: isSpecialAccount ? '#FFA726' : '#333', // Custom color for dark theme
          marginBottom: '20px',
        }}
      >
        {intl.formatMessage({ id: 'explorer.title' })}
      </Title>
      <Center>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "20px" }}>
          {qrCodeImageUrl && (
            <img src={qrCodeImageUrl} alt="QR Code" style={{ maxWidth: '100%' }} />
          )}
          <Column>
            <Title
              order={3}
              weight={200}
              align="center"
              style={{
                marginTop: '20px',
                marginBottom: '30px',
                color: isSpecialAccount ? '#FFA726' : '#333', // Custom color for special account
                fontSize: isSpecialAccount ? '1.8rem' : '1.5rem', // Slightly larger text for special account
              }}
            >
              {<FormattedMessage id="explorer.section.pinfo" />}
            </Title>
            <ProductState product={productDict} style={{ marginTop: "20px"}}/>
          </Column>
          <Column>
            <Title
              order={3}
              weight={200}
              align="center"
              style={{
                marginTop: '20px',
                marginBottom: '30px',
                color: isSpecialAccount ? '#FFA726' : '#333', // Custom color for special account
                fontSize: isSpecialAccount ? '1.8rem' : '1.5rem', // Slightly larger text for special account
              }}
            >
              {<FormattedMessage id="explorer.section.phistory" />}
            </Title>
            {isLoading ? (
              <Loader />
            ) : (
            <Column>
              <ProductTimeline productHist={productHist} style={{ margin: "auto" }}/>
            </Column>
            )}
          </Column>
        </div>
      </Center>
    </div>
    </MantineProvider>
  );
};

// You may want to implement ProductTimeline similarly to how it was done in ProductCertificate

export default CertificatePage;